<template>
    <div class="container">
        <Y-header></Y-header>

        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="600px" :speed="bannerSpeed"></banner>
            <center-title text="行业最新资讯" bold font-size="42" position="center"></center-title>
            <center-title text="围绕企业定制开发服务的数字化管理和设备互联，构建智慧产品服务" bold font-size="20" color="#808080" position="center"></center-title>

            <div class="list-container">
                <h2 class="title">行业资讯</h2>
                <div class="tabs-button">
                    <span class="button" v-for="(item,i) in tabsButton" :class="[i==active?'buttonActive':'']" :key="i" @click="tabsClick(item,i)">{{item}}</span>
                </div>
                <div class="list">
                    <div class="list-item" v-for="(item,i) in renderData" :key="i" @click="itemClick(item)">
                        <img class="cover" :src="item.coverImg.url"/>
                        <div class="item-title">
                            <div class="title-box">
                                <span class="title-text">{{item.publisher}}</span>
                                |
                                <span class="title-text">{{item.name}}</span>
                            </div>
                        </div>
                        <div>
                            <p class="content">{{item.createTime}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <a-pagination  :show-total="total => `共 ${total} 条`" style="text-align: center;padding: 20px" @change="pageChange" v-model="current" :total="total" show-less-items />
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import Banner from "@/components/banner.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import CenterTitle from "@/components/centerTitle.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, newsRequest, selectNews} from "@/api/apis";
export default {
    name: "news",
    components: {
        CenterTitle,
        Banner,
        'Y-footer':footer,
        'Y-header':header,
    },
    data() {
        return {
            current:1,
            pageSize:9,
            total:0,
            bannerData:[],
            bannerSpeed:2000,
            listData:[],
            renderData:[],
            active:0,
            tabsButton:[],
        }
    },
    mounted() {
        this.getBannerData();
        this.getListData();
    },
    methods: {
        tabsClick(type,i){
            this.active = i
            if(type == '全部'){
                this.renderData = this.listData
                return
            }
            let arr = []
            this.listData.forEach(item=>{
                if(item.type == type){
                    arr.push(item)
                }
            })
            this.renderData = arr
        },
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'最新动态',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner
        },
        async getListData(){
            let params = {
                page:this.current,
                pageSize:this.pageSize,
                state:1
            }
            const res = handleRequest(await selectNews(params))
            console.log(res)
            this.current = res.page+1
            this.total = res.totalElements
            let data = res.content
            this.listData = data
            this.tabsButton = ['全部']
            this.listData.forEach(item=>{
                this.tabsButton.push(item.type)
                item.coverImg = JSON.parse(item.coverImg)[0]
            })
            this.renderData = this.listData
            this.renderData = JSON.stringify(this.renderData)
            this.renderData = JSON.parse(this.renderData)
            this.tabsButton = Array.from(new Set(this.tabsButton))

        },
        pageChange(page,pageSize){
            this.current = page
            this.getListData()
        },
        itemClick(item){
            this.$router.push({
                path:'/newsDetail',
                query:{id:item.id}
            })
        },
    },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1920px){
    .list-container{
        margin: 0 170px !important;
        .list-item{
            margin: 17px !important;
        }
    }
}
.container{
    .body-container{
        background-color: #FFFFFF;

        .blocker{
            height: 92px;
        }
        .list-container{
            margin: 0 280px;
            .title{
                font-size: 24px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #3769FE;
                line-height: 24px;
                margin-left: 22px;
            }
            .tabs-button{
                padding: 20px 0;
                margin-left: 22px;
                .buttonActive{
                    background-color: #3769FE !important;
                    color: #FFFFFF !important;
                }
                .button{
                    cursor: pointer;
                    user-select: none;
                    color: #333333;
                    margin-right: 10px;
                    font-size: 16px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #F1F8FF;
                }
            }

            .list{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .list-item{
                    height: 320px;
                    transition: all .8s ease-in-out;
                    margin: 22px;
                    border-radius: 12px;
                    width: 30%;
                    border-radius: 12px;
                    &:hover{
                        transform: scale(1.1);
                    }
                    .cover{
                        transition: all .3s ease-in-out;
                        width: 100%;
                        height: 170px;
                        object-fit: cover;
                        border-radius: 12px;
                        margin-bottom: 10px;
                        &:hover{
                            box-shadow: 0 0 2px 3px #d7d7d7;

                        }
                    }
                    .content{
                        font-size: 20px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 36px;
                    }
                    .item-title{
                        margin-bottom: 10px;
                        .title-box{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            .title-text{
                                padding:0 10px;
                                font-size: 16px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #505050;
                                line-height: 16px;
                            }
                            .icon{
                                color: #FFFFFF;
                                background: linear-gradient(90deg, #EB2201 0%, #EA5E21 99%);
                                border-radius: 4px;
                                padding: 3px;
                            }
                        }
                        .arrow{
                            width: 26px;
                        }
                    }
                }
            }
        }
    }
}

</style>
